import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import { VideoPlayer } from "@components/video"

import {
  ScrollToButton, LearnMoreButton,
} from "@components/buttons"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const IndexPage = () => {
  return (
    <PageLayout>
      <SEO
        title="Psycheler: Music Production Framework"
        ogDescription="Psycheler is a music production framework developed by Jimmy Ether for generating music content from an initial production inspiration."
        url="https://psycheler.com"
      />
      <PageWrapper color="blue">
        <SingleColumnBlock
          textPadded
          centered
          text={
            <div>
              <h1>Psycheler: Music Production Framework</h1>
              <p>
                <a href="https://jimmyether.com">Jimmy Ether</a> developed the Psycheler music production framework. It's a method of generating a lot of music content from an initial production inspiration.
              </p>
            </div>
          }
        />
        <SingleColumnBlock
          textPadded
          text={
            <div>
              <h2>The 4-Step Psycheler Process</h2>
              <p>
                Step 1. Select a song from any era or genre that interests you. Study the composition and evaluate how they recorded it.
              </p>
              <p>
                Step 2. Write and record a new original song using those methods. Make it sounds like it could have come from the same sessions.
              </p>
              <p>
                Step 3. Sample and manipulate the resulting song creating an array of beats and loops.
              </p>
              <p>
                Step 4. Build a new final song using the beats and loops as a starting point.
              </p>
              <h3>Results</h3>
              <p>
                At the end of the process, you will have:
              </p>
              <ul>
                <li>One "pastiche" song that you can release.</li>
                <li>Several beats that you can use for other songs or license as-is for sync or to other artists.</li>
                <li>One final single built upon all the previous elements.</li>
              </ul>
            </div>
          }
        />
      </PageWrapper>
      <PageWrapper color="charcole">
        <SingleColumnBlock
          textPadded
          text={
            <div>
              <h2>Psycheler: Why go to all this trouble?</h2>
              <p>
                This process provides a range of benefits. First, there's the learning benefit of Step 1. It takes historical research, careful listening, and logistical thought to work out how someone else made a recording. Working that out, you are sure to learn a thing or two.
              </p>
              <p>
                Step 2 solves a couple of issues when it comes to sampling. Sample clearance and finding undiscovered source material. We are making the source material from scratch in the style of the original influence track. So, we don't have to worry about biting what others have sampled. Better yet, we don't have to worry about clearing samples. This opens up more sync and licensing opportunities.
              </p>
              <p>
                Step 3 provides us with unique beats and music beds. We never could have created these without the first two steps. The key here is giving ourselves something to react to.  It's the same as if you were listening for sample opportunities on other albums. You react by hearing a different way to flip or chop the song. And that's something you never could have anticipated in the earlier steps.
              </p>
              <p>
                Step 4 can go in any direction you like. By the end of the process, you may only hear faint hints of the previous steps. But you never would have arrived at this conclusion without them.
              </p>
              <h3>Randomness and Chance</h3>
              <p>
                Playing in the room with other musicians is fruitful because players introduce ideas. On the spot, you can react in real-time. Often, new directions come from a simple mistake one player makes. That happens to sound cool or spark an idea. These are often the best parts of the song. It never would have happened if those particular people hadn't been in the room together.
              </p>
              <p>
                When you build a production yourself in the studio, such opportunities are few. But, by introducing a process like Psycheler, you force randomness and chance into your workflow. You get something to react to.
              </p>
            </div>
          }
        />
      </PageWrapper>
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h2>Video Tutorials Coming</h2>
            <p>
              It's one thing to lay all this out, but I know you want to see it at work. So, the first of the Psycheler series videos are in the works. I'll be creating a new song based on the influence of a 7" I've pulled at random from my collection. I'm starting with "Ma Belle Amie" by the Tee Set.
            </p>
            <p>
              I'll share with you how I've broken down the recording to figure out how they did it. I'll show you the song I wrote to sound as if it came from the same recording session. And I'll show how I've manipulated it to create beats and an entirely new song. Stay tuned! Or <a href="https://www.youtube.com/channel/UC_wJrTHrWsYY3VvNxYMkBfg" target="_blank">subscribe to me on YouTube</a> and click the bell icon so you'll get notified when videos are posted. I'll also be doing live streams on Tuesday and Thursdays if you want to watch and ask questions.
            </p>
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h2>Get in touch!</h2>
            <RequestMoreInfo />
          </div>
        }
      />
    </PageLayout>
  )
}

export default IndexPage
